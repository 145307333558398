
//custom components
import SectionHeader from "@/components/SectionHeader.vue"
import dropdown from 'vue-dropdowns';
import Dialog from "@/components/Dialog.vue";
import TicketSearch from "@/components/TicketSearch.vue";

// axios
import axios from "axios";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Sales",
  data() {
    return {
      sectionIcon: '/assets/icons/ticket.svg',
      sectionName: 'Ventas Web',
      loading: true,
      sales: [] as any[any],
      cinemaSelectOptions: [] as any[any],
      searchSaleId: '',
      showDialog: false,
      dialogMessage: "",
      onDialogContinue: Function as any,
      onDialogCancel: Function as any,
      queryType: ''
    };
  },
  components: {
    SectionHeader,
    dropdown,
    Dialog,
    TicketSearch
  },
  async created() {
    //create selector for cinemas
    this.$store.state.cinemas.forEach((cinema: any) => {
      this.cinemaSelectOptions.push({ id: cinema.cinemaId, name: cinema.cinemaName })
    });

    //set default cinema
    let cinemaId = this.$store.state.cinemas[0].cinemaId;

    //get selected cinema, if any
    if (this.$store.state.selectedCinema) {
      cinemaId = this.$store.state.selectedCinema;
    } else {
      this.$store.commit("setSelectedCinema", cinemaId);
    }

    this.getSales();
  },
  methods: {
    async getSales(queryData = {value: 'code', query: '', label: 'Código'}) {
      //set loading
      this.loading = true;

      //set query type
      this.queryType = queryData.label

      //get api url
      const apiUrl = (this as any).apiUrl;

      //endpoint
      const endpoint = (queryData.query == '')
        ? "/webSales/" + this.$store.state.selectedCinema
        : "/webSales/" + this.$store.state.selectedCinema + "/" + queryData.value + "/" + queryData.query

      //get sales
      const getSales = await axios
        .get(apiUrl + endpoint)
        .catch((error) => {
          console.log(error.response);
          throw "Error de Servidor";
        });

      //set sales
      this.sales = getSales.data.data.sales;

      //set loading
      this.loading = false;
    },
    getStatus(status: string) {
      if (status == 'Pagada') {
        return 'No descargada'
      }

      if (status == 'Entregada') {
        return 'Entregada'
      }

      if (status == 'Descargada') {
        return 'Lista para retirar'
      }
    },
    getStatusColor(status: string) {
      if (status == 'Pagada') {
        return 'bg-red-500'
      }

      if (status == 'Entregada') {
        return 'bg-green-500'
      }

      if (status == 'Descargada') {
        return 'bg-yellow-500'
      }
    },
    updateSelectedCinema(cinemaInfo: any[any]) {
      const cinemaId = cinemaInfo.id
      this.$store.commit('setSelectedCinema', cinemaId)
      this.getSales()
    },
    initRefund(sale: any[string]) {
      // get cinemaId
      const cinemaId = this.$route.params.cinemaId;

      //init dialog message
      this.dialogMessage = 'Estás a punto de iniciar una devolución<br>para la compra con el código de retiro:<br><b>'

      //set dialog message
      this.dialogMessage +=
        sale.codigoVenta +
        "</b><br>realizada por<br><b>" +
        sale.email +
        "</b>";

      //set callbacks
      this.onDialogContinue = () => {
        this.$router.push("/reembolso/" + sale.codigoVenta)
      };

      this.onDialogCancel = () => {
        this.showDialog = false;
      };

      //show dialog
      this.showDialog = true;
    },
    getCurrentCinemaName(cinemaId: number){
      let cinemaName = 'Seleccione un cine'

      this.$store.state.cinemas.forEach((cinema: any) => {
        if(cinema.cinemaId == cinemaId){
          cinemaName = cinema.cinemaName
        }
      })

      return cinemaName
    }
  },
});
