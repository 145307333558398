
export default {
  name: "Button",
  props: {
    iconOnly: {
      type: Boolean,
      default: false
    }
  }
};
