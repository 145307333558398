
// axios
import axios from "axios";

import { defineComponent } from 'vue'

export default defineComponent({
    name: "SearchTicket",
    emits: ['onUpdated', 'onCancel'],
    data() {
        return {
            searching: false,
            queryOptions: [
                {
                    label: 'Código',
                    value: "code"
                },
                {
                    label: "Email",
                    value: "email"

                },
                {
                    label: "DNI",
                    value: "doc"
                }
            ],
            openSelector: false,
            queryData: {
                label: 'Código',
                value: "code",
                query: ''
            },
            sales: [] as any[any]
        }
    },
    mounted() {
        (this.$refs as any).searchInput.addEventListener('focus', () => {
            if (this.openSelector) {
                this.openSelector = false
            }
        })
    },
    onUnmounted() {
        (this.$refs as any).searchInput.removeEventListener('focus')
    },
    methods: {
        toggleDropdown() {
            this.openSelector = !this.openSelector
        },
        setqueryData(queryData: any) {
            (this.$refs as any).searchInput.focus()
            queryData.query = ''
            this.openSelector = false
            this.queryData = queryData
        },
        searchSale() {
            if (this.queryData.query.length >= 5 && !this.searching) {
                this.searching = true
                setTimeout(()=>{
                    this.$emit('onUpdated', this.queryData)
                    this.searching = false
                },500)  
            }
        },
        cleanSearch() {
            this.openSelector = false
            this.queryData.query = ''
            this.$emit('onCancel')
        }
    }
})
