

import { defineComponent } from 'vue'
import Button from "@/components/Button.vue"

export default defineComponent({
    name: "Dialog",
    emits: ['onConfirm' ,'onCancel'],
    components:{
        Button
    },
    methods: {
        confirm: function(){
            this.$emit('onConfirm')
        },
        cancel: function(){
            this.$emit('onCancel')
        }
    },
    props:{
        open: {
            type: Boolean,
            default: false
        },
        message: {
            type: String
        },
        showCancelButton:{
            type: Boolean,
            default: true
        }
    }  
})


